import { useMatch, useNavigate } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { OefenincidentForm } from "../../../forms/OefenincidentForm";
import { NotFound } from "../../Invalid/NotFound";
import { Oefenincident } from "../../../models/Oefenincident";
import { DetermineApiUri } from "../../../../config";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { defaultToastOptions } from "../../../utils/defaultToastOptions";
import { ToastContainer, toast } from "react-toastify";
import { Routes } from "../../../routes";
import { useProtectedFetch } from "../../../hooks/useProtectedFetch";
import { MeldkamerButton } from "../../../components/IconButtons/MeldkamerButton";
import style from "./style.module.scss";

/**
 * Page for editing an oefenincident
 */
export function EditOefenincidentPage() {
  const match = useMatch("oefenincident/edit/:incidentNr");
  const { incidentNr } = match?.params as { incidentNr: string };

  const request = useProtectedFetch("none");
  const navigate = useNavigate();

  const { data: existingIncident } = useSWR<Oefenincident>(incidentNr ? `${DetermineApiUri()}/api/oefenincident/${incidentNr}` : null, {
    fetcher: useProtectedFetch(),
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    revalidateOnMount: true,
  });

  if (!incidentNr)
    return <NotFound />;

  if (!existingIncident)
    return <LoadingComponent />;

  async function patchOefenincident(oefenIncident: Oefenincident) {
    try {      
      await request(`${DetermineApiUri()}/api/oefenincident`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(oefenIncident),
      });
      toast.success("Oefenincident aangepast.", defaultToastOptions);
      mutate(`${DetermineApiUri()}/api/oefenincident/${incidentNr}`);
      navigate(Routes.OefenincidentOverview);
    } catch (error) {     
      toast.error(
        "Er is iets fout gegaan met het opslaan van de wijzigingen",
        defaultToastOptions
      );
    }
  }

  function onEdit(): void {
    navigate(`/oefenincident/meldkamer/${incidentNr}`);
  }

  return (
    <div className={"content-container"}>
      <ToastContainer />
      <div className={style.header}>
        <h1>Oefenincidenten: {incidentNr} </h1>
        <MeldkamerButton onClick={() => onEdit()}></MeldkamerButton>
      </div>
      <OefenincidentForm onSubmit={patchOefenincident} initOefenincident={existingIncident} />
    </div>
  );
}